<script>
	import { t } from '@woltair/translations-package-fe'

	export let boxes = null
	export let column = false
	export let subtext = false
	export let maxWidth = null
	export let itemsConfig = null
	export let cls = ''
	export let woltadvisor = false
</script>

<section
	class="boxes {cls} flex flex-col items-center md:flex-row {woltadvisor
		? 'grid grid-cols-1 justify-items-center align-middle md:grid-cols-2'
		: ''}"
	class:woltadvisor
	class:column
	style:max-width={maxWidth}
>
	{#each boxes as { title, description, icon, slot = '' }}
		<section
			class="box"
			style:min-width={itemsConfig?.minWidth}
			style:max-width={itemsConfig?.maxWidth}
			style:width={itemsConfig?.width}
			style:flex={itemsConfig?.flex}
		>
			<div class="flex justify-center">
				<img
					class="h-[70px]"
					src={icon?.src || icon.url}
					alt={t(icon?.alt) || t(icon.alternativeText)}
				/>
			</div>
			<div>
				<div>
					<p class:text-red={title?.red} class:fw-extra-bold={title?.extrabold} class="mb-0">
						{@html t(title?.text) || t(title)}
					</p>
					{#if description}
						<p class:subtext>{@html t(description)}</p>
					{/if}
				</div>
				{@html slot}
			</div>
		</section>
	{/each}
</section>

<style lang="scss">
	:root {
		--box-text-align: left;
	}

	.boxes {
		flex-wrap: wrap;
		margin: 50px 0;
		gap: 15px;
		margin: 0 auto;
		justify-content: center;
		max-width: var(--wrapper-max-width);
		max-width: var(--wrapper-max-width);

		.box {
			display: flex;
			gap: 15px;
			align-items: center;
			flex: 1 0 0;

			div:first-child {
				min-width: 70px;
			}

			div {
				p {
					margin: 5px 0;
					text-align: var(--box-text-align);

					&:first-child {
						font-weight: 550;
					}
				}

				img {
					display: block;
				}
			}
		}

		&.column {
			.box {
				flex-direction: column;
				align-items: center;
				text-align: center;
			}
		}
	}

	.woltadvisor {
		width: 100%;
		display: grid;
		gap: 2rem;
		margin: 2.5rem 0 auto 0;
	}
</style>

<script>
	import { t } from '@woltair/translations-package-fe'
	import { createEventDispatcher, getContext } from 'svelte'
	import { ErrorMessage } from './index.js'
	import { key } from './key.js'

	// Exported Props
	export let name = null
	export let type = 'text'
	export let flex = 'justify-start'
	export let label = ''
	export let unit = ''
	export let tiny = false
	export let wrap = false
	export let width = null
	export let margin = ''
	export let input = {}
	export let center = true
	export let hint = null
	export let cls = ''
	export let optional = false
	export let maxWidth = '350px'
	export let justify = null
	export let testId = null

	// Internal States
	let inputFocused = false
	let isVerified = false
	let showLoading = false
	let optionalVisible = false
	let inputThis
	let affiliateCodeShow = false
	let affiliateCodeValid = false
	let { form, handleChange, errors } = getContext(key)

	const dispatch = createEventDispatcher()
	const affilEvent = new Event('affiliateEvent', { bubbles: true })

	// Event Handlers
	const onInput = () => {
		isVerified = false
		dispatchAffilOkay(true, false, false)
	}
	const onFocus = () => (inputFocused = true)
	const onBlur = () => (inputFocused = false)

	// Helper Functions
	const dispatchAffilOkay = (value, checking, isOkay) => {
		affilEvent.affil = { visible: value, checking, isOkay }
		showLoading = !checking
		dispatchEvent(affilEvent)
	}

	const getTestId = (element) =>
		(testId && element === 'label' && ['radio'].includes(type)) ||
		(element === 'input' && !['radio'].includes(type))
			? testId
			: null

	// API Call and Handlers
	async function checkAffiliate() {
		if (!inputThis.value) return { code: false }
		try {
			const response = await fetch('/api/affiliate', {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify({ searchID: inputThis.value })
			})
			if (!response.ok) throw new Error(`HTTP error! status: ${response.status}`)
			return await response.json()
		} catch (error) {
			console.error('Error fetching data:', error)
			return null
		}
	}

	async function handleSelected(event) {
		dispatchAffilOkay(true, true, false)
		const afil = await checkAffiliate()
		if (afil && afil === 'VALID') {
			dispatchAffilOkay(true, false, true)
			isVerified = true
			affiliateCodeValid = true
			$errors['affiliateCodeValid'] = ''
			$form['affiliateCodeValid'] = true
			dispatch('selected', $form)
		} else {
			dispatchAffilOkay(affiliateCodeShow, false, false)
			isVerified = true
			affiliateCodeValid = false
			$form['affiliateCodeValid'] = false
			$errors['affiliateCodeValid'] = t('forms.validations.affiliationCode.error')
		}
		handleChange(event)
	}

	function clickOptional() {
		optionalVisible = !optionalVisible
	}

	// External Functions
	export function focus() {
		inputThis.focus()
	}

	export function fill(val) {
		inputThis.value = val
	}

	delete $$props.testId // Prevent having both data-testid and testid atts on input
</script>

{#if optional}
	<svelte:self type="checkbox" on:click={clickOptional}>
		<p class="d-inline m-0 text-left font-normal">{optional?.text || 'Fill label text'}</p>
	</svelte:self>
{/if}
{#if !optional || optionalVisible}
	<label for="affiliateCodeShow" class="flexlabel {flex}">
		<input
			style={tiny ? 'display:none' : ''}
			bind:checked={affiliateCodeShow}
			id="affiliateCodeShow"
			name="affiliateCodeShow"
			type="checkbox"
			on:change={(event) => {
				isVerified = false
				dispatchAffilOkay(affiliateCodeShow, false, false)
				if (!affiliateCodeShow) {
					$form['affiliateCode'] = ''
				}
				handleChange(event)
			}}
		/>
		<span
			style={tiny
				? 'text-decoration:underline;font-size:0.8rem; cursor:pointer;'
				: 'padding-left:1rem'}
		>
			{label}
		</span>
	</label>

	{#if affiliateCodeShow}
		<label
			class="{unit} {type} {margin} {cls}"
			style:max-width={maxWidth}
			style:width
			style:justify-content={justify}
			class:unit
			class:center
			class:inline-flex={wrap}
			data-testid={getTestId('label')}
		>
			<input
				{name}
				{type}
				bind:this={inputThis}
				value={name && $form[name]}
				data-testid={getTestId('input')}
				style:text-align={input.textAlign || 'left'}
				style:padding={input.padding || '15px 10px'}
				style:font-size={input.fontSize || '20px'}
				on:focus={onFocus}
				on:blur={onBlur}
				on:input={onInput}
				on:paste,
				on:click,
				on:keyup={(event) => {
					dispatch('keyup', event)
				}}
				on:blur={(event) => {
					dispatchAffilOkay(true, true, false)
					handleSelected(event)
				}}
				{...$$restProps}
			/>
		</label>

		<div class="flex max-h-5 flex-row justify-center gap-4" class:hidden={showLoading}>
			<p>{t('web.forms.leaveContact.promocode.checking')}</p>
			<div class="aspect-square max-h-5">
				<img
					class="max-h-full max-w-full animate-spin"
					src="/images/icons/circle/loading.svg"
					alt={t('web.forms.leaveContact.promocode.checking')}
				/>
			</div>
		</div>

		<button
			class="cursor-pointer underline hover:font-semibold"
			class:hidden={!inputFocused || (inputFocused && isVerified)}
		>
			{t('web.forms.leaveContact.promocode.validate')}
		</button>

		<ErrorMessage {name} {maxWidth} />
		{#if affiliateCodeValid}
			<span class="text-green-500" class:hidden={!showLoading || !isVerified}
				>{t('web.forms.leaveContact.promocode.correct')}</span
			>
		{/if}
		<div class:hidden={!showLoading || !isVerified}>
			<ErrorMessage name="affiliateCodeValid" {maxWidth} />
		</div>

		<label for="affiliateCodeValid" class="flexlabel">
			<input
				bind:checked={affiliateCodeValid}
				value={affiliateCodeValid}
				id="affiliateCodeValid"
				name="affiliateCodeValid"
				type="checkbox"
				on:change={(event) => {
					handleChange(event)
				}}
				class="hidden"
			/>
		</label>
	{/if}
	{#if hint}
		<p class="text-gray hint mx-auto mb-2 pl-7 text-left text-sm" style:max-width={maxWidth}>
			<img src="/icons/hint.svg" alt="Hint" class="hint-icon" />
			{@html hint}
		</p>
	{/if}
{/if}

<style lang="scss">
	.pl2 {
		padding-left: 1em;
	}
	.flexlabel {
		display: flex;
		max-width: 350px;
		align-items: center;
	}
	label {
		position: relative;
		display: block;
		text-align: left;
		padding: 0 10px;
		margin: 15px auto 8px auto;
		font-size: 16px;
		width: 100%;

		&.radio {
			border: 2px solid #cccccc;
			cursor: pointer;
			padding: 15px 10px;
			transition: 0.4s;
			text-align: center;

			&.round {
				border-radius: 999px;
			}

			&.square {
				border-radius: 5px;
			}

			&:hover {
				border: 2px solid #e20613;
				color: #e20613;
			}

			input {
				//visibility: hidden;
				display: none;
				position: absolute;
			}
		}

		&.checkbox {
			display: flex;
			flex-direction: row-reverse;
			gap: 15px;

			input {
				flex-shrink: 0;
			}
		}

		&.inline-flex {
			display: inline-flex;
			margin: 5px;
		}

		input {
			appearance: none;
			outline: none;
			margin: 3px 0 0 !important;
			border-radius: 5px;
			transition: 0.2s;
			background: #fff;
			border: 2px solid #999999;
			box-shadow: 0 0 5px rgba(163, 163, 163, 0.5);
			-webkit-appearance: none;
			width: 100%;

			&::-webkit-outer-spin-button,
			&::-webkit-inner-spin-button {
				-webkit-appearance: none;
				margin: 0;
			}

			&[type='number'] {
				appearance: textfield;
				-moz-appearance: textfield;
			}

			&[type='checkbox'] {
				transition: 0.4s background-color;
				width: 35px;
				height: 35px;
				cursor: pointer;

				&:checked {
					background: theme('colors.primary-500');
					border: theme('colors.primary-500');
					background-image: url('$static/images/icons/check.svg');
					background-repeat: no-repeat;
					background-position: center;
					background-size: 30px 30px;
				}
			}

			@media only screen and (max-width: 760px) {
				font-size: 14px;
			}

			&::placeholder {
				color: #999;
				opacity: 1;
			}
		}
	}

	:global(.unit) {
		&::after {
			color: #666666;
			font-size: 18px;
			position: absolute;
			right: 20px;
			top: 18px;
		}

		& input[type='number'] {
			text-align: right !important;
		}
	}

	.unit {
		& input[type='number'] {
			padding-right: 45px !important;
		}
	}

	.unit.CZK {
		&::after {
			content: 'Kč';
		}
	}

	.unit.MWh {
		&::after {
			content: 'MWh';
		}

		& input[type='number'] {
			padding-right: 70px !important;
		}
	}

	.unit.m2 {
		&::after {
			content: 'm²';
		}
	}

	.unit.kwh {
		&::after {
			content: 'kWh';
		}
	}

	.unit.kw {
		&::after {
			content: 'kW';
		}
	}

	.hint {
		position: relative;
		.hint-icon {
			position: absolute;
			top: 5px;
			left: 10px;
		}
	}
</style>

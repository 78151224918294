<script>
	import { ListItem, UnorderedList } from '../components/index.js'
	export let listItems = []
</script>

<UnorderedList>
	{#each listItems as listItem}
		<ListItem>{listItem}</ListItem>
	{/each}
</UnorderedList>

<script>
	import { t } from '@woltair/translations-package-fe'
	import { getContext } from 'svelte'
	import { goto } from '$app/navigation'
	import { page } from '$app/stores'
	import { INSTALLATION_CATEGORY, STEP } from '$lib/consts'
	import { CALL_ME, HEAT_PUMP, GUARANTEE, GRANTS } from '$lib/routes'
	import { Button, IconedBoxes } from '$lib/ui-woltair/components'
	import CalculatorBenefits from './_abComponents/calculatorBenefits.svelte'
	import { key } from '../../form/key.js'

	export let abVersion = null
	export let gotoStep = '0'
	export let testId = null
	export let content
	export let deviceType
	export let boxes = null

	let { step } = getContext(key)

	function handleStart() {
		$step = gotoStep
		$page.url.searchParams.set(STEP, $step)
		goto($page.url.href)
	}

	function handleCall() {
		$page.url.searchParams.delete(STEP)
		$page.url.searchParams.set('device', deviceType)
		goto(`/${CALL_ME}${$page.url.search}`)
	}
</script>

<slot />
<img class="only-desktop" src={content.icon.url} alt={content.icon.alternativeText} />
<h1 class="unstyled center mt-10 text-2.5xl font-extrabold tracking-tight md:text-4.5xl">
	{@html content.heading}
</h1>
{#if abVersion == 'box_accordion'}
	<div class="accordion">
		<details>
			<summary class="h4 flex items-center justify-between pb-2 pt-2">
				Do 30 dnů nebo za půlku
			</summary>
			<div class="mb-6">
				Tepelné čerpadlo vám nainstalujeme do 50 dní od podpisu smlouvy. Pokud ne, získáte slevu 50
				%. <a href="/documents/VOP_HVAC_50_50_1-11-2023.pdf">Podmínky akce</a>
			</div>
		</details>
		<details>
			<summary class="h4 flex items-center justify-between pb-2 pt-2">
				Všechny značky tepelných čerpadel na jednom místě
			</summary>
			<div class="mb-6">
				Máme v nabídce <a href="/{HEAT_PUMP}#comparison">tepelná čerpadla</a> všech známých výrobců a
				pro váš dům vybereme to opravdu nejvhodnější.
			</div>
		</details>
		<details>
			<summary class="h4 flex items-center justify-between pb-2 pt-2">
				120 montáží každý měsíc po celém Česku s vlastním montážním týmem
			</summary>
			<div class="mb-6">
				Jsme jeden z největších dodavatelů tepelných čerpadel v Česku. <a
					href="/{HEAT_PUMP}#realizations">Naše realizace</a
				>
			</div>
		</details>
		<details>
			<summary class="h4 flex items-center justify-between pb-2 pt-2">
				Garance dotace ve výši až 100 000 Kč
			</summary>
			<div class="mb-6">
				Ověříme váš nárok na <a href="/{HEAT_PUMP}/{GRANTS}">dotaci</a> a zařídíme za vás celou administrativu.
			</div>
		</details>
		<details>
			<summary class="h4 flex items-center justify-between pb-2 pt-2">
				Prodloužená záruka až 10 let
			</summary>
			<div class="mb-6">
				10letá záruka se servisními prohlídkami v ceně, za roční poplatek 5990 Kč. <a
					href="/{GUARANTEE}">Více</a
				>
			</div>
		</details>
	</div>
{/if}
{#if abVersion !== 'box_accordion'}
	<p class="unstyled center my-10 text-lg text-neutral-500">{@html content.text}</p>
{/if}

{#if abVersion}
	<CalculatorBenefits />
{/if}

<div class="center">
	<Button
		on:click={handleStart}
		width={abVersion ? '300px' : false}
		icon={!abVersion ? { src: '/images/icons/icon-calculator.svg' } : false}
		{testId}
	>
		{t('woltadvisor.startCalc')}
	</Button>
</div>
{#if $page.data.featureConfig.meeting.hvac || $page.data.featureConfig.meeting.pv}
	<div class="center mt-1">
		<Button
			on:click={handleCall}
			width={abVersion ? '300px' : false}
			secondary
			noWrap
			icon={!abVersion ? { src: '/images/avatars/team.webp' } : false}
			>{t('woltadvisor.callMe')}</Button
		>
	</div>
{/if}
{#if content?.subtext}
	<p class="unstyled center my-6 text-sm text-neutral-500">{@html content?.subtext}</p>
{/if}
{#if abVersion !== 'box_accordion'}
	<h4 class="unstyled mb-3 mt-5 text-2.5xl font-semibold">
		{t('woltadvisor.whyChooseWoltair.title')}
	</h4>
	<section class="flex">
		<div class="flex-zero w-1/2 text-right">
			<h5 class="text-1.5xl font-semibold">93 %</h5>
			<p class="my-4 text-right text-lg text-neutral-500">
				{t('woltadvisor.whyChooseWoltair.recommendations')}
			</p>
		</div>
		<div class="divider" />
		<div class="flex-zero w-1/2 text-left">
			<h5 class="text-1.5xl font-semibold">200</h5>
			<p class="my-4 text-left text-lg text-neutral-500">
				{t('woltadvisor.whyChooseWoltair.montages')}
			</p>
		</div>
	</section>

	<p class="my-10 mb-5 text-lg text-neutral-900">
		{#if deviceType === INSTALLATION_CATEGORY.TEPELNE_CERPADLO}
			{t('woltadvisor.whyChooseWoltair.heat_pump.desc')}
		{:else}
			{t('woltadvisor.whyChooseWoltair.desc')}
		{/if}
	</p>

	{#if boxes}
		<IconedBoxes {boxes} subtext woltadvisor itemsConfig={{ maxWidth: '300px' }} />
	{/if}
{/if}

<style>
	.accordion {
		@apply mb-8;
	}
	.accordion a {
		@apply text-primary-500;
	}
	.accordion details {
		@apply relative my-0 border-b border-neutral-100 py-4 pl-0 text-left;
	}
	.accordion details:last-child {
		@apply border-b-0;
	}
	.accordion details:before {
		position: absolute;
		pointer-events: none;
		left: 0;
		top: 50%;
		filter: grayscale(100%);
		opacity: 0.5;
		transform: translateY(-50%) rotate(-90deg);
		display: block;
		content: '';
		height: 7px;
		width: 10px;
		background-size: contain;
		background-position: 0 50%;
		background-repeat: no-repeat;
		background-image: url('/images/icons/arrow-down-red.svg');
	}
	.accordion details[open]:before {
		filter: none;
		opacity: 1;
		transform: translateY(-50%) rotate(0deg);
	}
	.accordion summary {
		@apply my-0 cursor-pointer pl-8 text-lg font-bold;
	}
	.accordion details > div {
		@apply pl-8;
	}
	.divider {
		margin: 0 15px;
		border: 1px solid #999;
	}

	img {
		display: block;
		margin: 0 auto;
		height: 100px;
	}
</style>

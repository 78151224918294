import { persisted as writableLocal } from 'svelte-local-storage-store'
import { boolean, number, object, string } from 'yup'

export { default as LocationCZ } from './LocationCZ.svelte'
export { default as Location } from './Location.svelte'

export { default as Address } from './Address.svelte'
export { default as Affiliate } from './Affiliate.svelte'
export { default as ErrorMessage } from './ErrorMessage.svelte'
export { default as Field } from './Field.svelte'
export { default as Form } from './Form.svelte'
export { default as Frame } from './Frame.svelte'
export { default as NextStep } from './NextStep.svelte'
export { default as PrevStep } from './PrevStep.svelte'
export { default as Select } from './Select.svelte'
export { default as Step } from './Step.svelte'
export { default as Textarea } from './Textarea.svelte'
export { default as TermPicker } from './TermPicker.svelte'
export { default as Wizzard } from './Wizzard.svelte'
export { default as UnorderedList } from './UnorderedList.svelte'
export { default as OptionButton } from './OptionButton.svelte'
export { default as MultiField } from './MultiField.svelte'
export { default as SignaturePad } from './SignaturePad.svelte'

export { default as FirstStep } from '../modules/woltAdvisor/FirstStep.svelte'
export { default as CalculatorBenefits } from '../modules/woltAdvisor/_abComponents/calculatorBenefits.svelte'
export { default as Element } from '../components/Element.svelte'
export { default as Button } from '../components/Button.svelte'

export const storedValues = writableLocal('storedValues', {})

export function jsonToString(value) {
	return typeof value === 'object' ? JSON.stringify(value) : value
}

export const v = {
	number: () => number(),
	bool: (text) => boolean(text),
	checkbox: (text) => boolean().oneOf([true], text),
	numberReq: (req, positive, type) =>
		number().typeError(type).nullable().required(req).positive(positive),
	string: () => string(),
	stringReq: (text) => string().required(text),
	object: (text) => object().nullable().required(text)
}

<script>
	import { createEventDispatcher, onMount, tick } from 'svelte'
	import { get_current_component } from 'svelte/internal'
	import { slide } from 'svelte/transition'
	import { ErrorMessage } from '../form/index.js'

	export let items = () => []
	export let value = null
	export let placeholder = null
	export let name = 'autocomplete'
	export let disabled = false
	export let required = false
	export let options = null
	export let error = null
	export let success = null
	export let label = null
	export let cls = ''
	export let icon = null
	export let width = '100%'
	export let maxWidth = '100%'
	export let paddingRight = '10px'
	export let left = false
	export let googleLogo = false
	export let testId = null

	const component = get_current_component()
	const dispatch = createEventDispatcher()

	let _value = null
	let data = []
	let active = false
	let fakeInputElement = null
	let formInput = null
	let formInputHeight = null
	let itemsElement = null
	let itemElement = []
	let placeholderHidden = false

	let textContext
	let htmlContext

	let selectedItem
	let selectedItemSlot = null
	let selectedIndex = 0

	let loading = false
	let timer = null

	function onKeyDown(event) {
		switch (event.key) {
			case 'Backspace':
				//if (value) clearInput();
				break

			case 'ArrowDown':
				selectNextItem(event)
				break

			case 'ArrowUp':
				selectPrevItem(event)
				break

			case 'Enter':
				enterPressed(event)
				break

			default:
				anotherKeyPressed(event)
				break
		}
	}

	function scrollInsideItems() {
		const element = itemElement[selectedIndex]
		if (!element) return

		const elementScrollTop = Number(element.offsetTop) + Number(element.offsetHeight)
		const itemsScrollTop = Number(itemsElement.scrollTop) + Number(itemsElement.offsetHeight)

		if (elementScrollTop > itemsScrollTop) itemsElement.scrollTop += element.offsetHeight
		else itemsElement.scrollTop -= element.offsetHeight
	}

	function anotherKeyPressed(event) {
		if (!value) return
		if (
			(event.keyCode >= 48 && event.keyCode <= 57) ||
			(event.keyCode >= 65 && event.keyCode <= 90)
		) {
			//clearInput();
		}
	}

	function enterPressed(event) {
		event.preventDefault()
		if (!selectedItem) return
		selectItem(selectedItem, true)
	}

	function selectNextItem(event) {
		event.preventDefault()
		if (activeWithData) {
			if (selectedIndex + 1 > data.length - 1) return
			selectedItem = data[++selectedIndex]
			scrollInsideItems()
		}
	}

	function selectPrevItem(event) {
		event.preventDefault()
		if (activeWithData) {
			if (selectedIndex - 1 < 0) return
			selectedItem = data[--selectedIndex]
			scrollInsideItems()
		}
	}

	function isSelected(item, selectedItem) {
		return item === selectedItem
	}

	async function clearInput() {
		value = null
		_value = null
		htmlContext = ''
		textContext = ''
		if (fakeInputElement) {
			await tick()
			fakeInputElement.value = value
			fakeInputElement.dispatchEvent(new Event('change'))
		}
	}

	////////////////////////////////////////////////

	async function onBlur() {
		setTimeout(() => {
			if (fakeInputElement) {
				fakeInputElement.value = value
				fakeInputElement.dispatchEvent(new Event('blur'))
			}
			active = false
		}, 100)
	}

	function onFocus() {
		active = true
	}

	function onHoverItem(item, index) {
		selectedIndex = index
		selectedItem = item
	}
	////////////////////////////////////////////////

	function updatePlaceholder(textContext) {
		if (!textContext) placeholderHidden = false
		if (textContext) {
			placeholderHidden = textContext !== ''
		}
	}

	function labelClicked() {
		if (formInput) formInput.focus()
	}

	async function getData(searchText) {
		if (!searchText || searchText === '') {
			data = []
			return
		}

		if (timer) {
			clearTimeout(timer)
			timer = null
		}

		timer = setTimeout(async () => {
			loading = true
			data = await items(searchText)
			loading = false
			selectedItem = data?.[0] || null
			selectedIndex = 0
		}, 250)
	}

	async function selectItem(item, dispatchSelected) {
		value = { ...item }
		_value = { ...item }
		await tick()
		placeholderHidden = true
		htmlContext = selectedItemSlot?.innerHTML || ''
		await tick()
		active = false

		if (dispatchSelected) dispatch('selected', value)

		if (fakeInputElement) {
			fakeInputElement.value = value
			fakeInputElement.dispatchEvent(new Event('change'))
		}
	}

	$: updatePlaceholder(textContext)
	$: getData(textContext)
	$: activeWithData = active && data.length > 0

	$: {
		if (value && (value?.id !== _value?.id || value?.uid !== _value?.uid)) {
			selectItem(value)
		}
	}

	// reactive statement for clearing input
	$: {
		if (value === null && _value !== null) clearInput()
	}

	onMount(async () => {
		await tick()

		// SVELTEBUG: change parent node of fucking svelte iframe
		formInput.parentNode.append(formInput.childNodes[0])

		// prepare fake input element
		fakeInputElement.name = name
		fakeInputElement.type = 'input'

		// prefill if value is defined
		if (value) {
			placeholderHidden = true
			htmlContext = selectedItemSlot?.innerHTML || ''
		}

		Object.keys(component.$$.callbacks).forEach((event) =>
			component.$$.callbacks[event].forEach((handler) =>
				fakeInputElement.addEventListener(event, handler)
			)
		)
		return () => {
			Object.keys(component.$$.callbacks).forEach((event) =>
				component.$$.callbacks[event].forEach((handler) =>
					fakeInputElement.removeEventListener(event, handler)
				)
			)
		}
	})
</script>

<div class="fakeInputElement" bind:this={fakeInputElement} />

<div class="autocomplete {cls}" style:width style:max-width={maxWidth} class:left>
	<label class="form-label" class:has-error={error} class:has-success={success}>
		{#if label}
			<span class="label" role="presentation" on:click={labelClicked}>{label}</span>
		{/if}
		<div class="form-input-wrapper">
			{#if icon}
				<img src={icon.src} alt={icon.alt} style:right={paddingRight} />
			{/if}
			{#if disabled}
				<div
					bind:this={formInput}
					class="form-input"
					class:has-error={error}
					class:has-success={success}
					class:disabled
					bind:clientHeight={formInputHeight}
					contenteditable="false"
					bind:textContent={textContext}
					bind:innerHTML={htmlContext}
					{required}
					{...options}
					data-testid={testId}
					style:padding-right={paddingRight}
				/>
			{:else}
				<div
					data-testid={testId}
					bind:this={formInput}
					class="form-input"
					class:focus={active}
					class:has-error={error}
					class:has-success={success}
					class:disabled
					bind:clientHeight={formInputHeight}
					contenteditable="true"
					bind:textContent={textContext}
					bind:innerHTML={htmlContext}
					on:blur={onBlur}
					on:focus={onFocus}
					on:keydown={onKeyDown}
					style:padding-right={paddingRight}
					{required}
					{...options}
				/>
			{/if}
			{#if placeholder}
				<div
					class="placeholder-input"
					role="presentation"
					on:click={labelClicked}
					class:hidden={placeholderHidden}
				>
					{placeholder}
				</div>
			{/if}

			{#if loading}
				<div class="loading" />
			{/if}
			{#if active && data.length > 0}
				<div
					bind:this={itemsElement}
					class="items"
					transition:slide
					style="--height: {formInputHeight}"
				>
					{#each data as item, i}
						<div
							class="item"
							role="presentation"
							data-testId={item.testId
								? item.testId
								: testId
								? `${testId}-result-` + (i + 1)
								: null}
							class:icon={item?.icon}
							class:hover={isSelected(item, selectedItem)}
							bind:this={itemElement[i]}
							on:click={selectItem(item, true)}
							on:mouseover={onHoverItem(item, i)}
							on:focus={() => null}
						>
							<slot name="item" {item}>
								{#if item?.icon}
									<img src={item.icon.src} alt={item.icon.alt} />
								{/if}
								{@html item?.title ||
									item?.name ||
									item?.uid ||
									item?.id ||
									item?.WHOLE_ADDRESS ||
									item?.CITY ||
									item?.formattedText}
							</slot>
						</div>
					{/each}
					{#if googleLogo}
						<div class="powered-by-google">
							<img src="/images/logos/powered-by-google.webp" alt="Google" />
						</div>
					{/if}
				</div>
			{/if}
		</div>
		<ErrorMessage {name} {maxWidth} />
	</label>
</div>

<div class="selectedItemSlot" bind:this={selectedItemSlot}>
	<div class="selectedItemSlotInner">
		<slot name="selected" item={value}>
			{value?.title || value?.name || value?.uid || value?.id}
		</slot>
	</div>
</div>

<style lang="scss">
	.fakeInputElement {
		display: none;
	}

	.selectedItemSlot {
		display: none;
	}

	.selectedItemSlotInner {
		width: 100%;
	}

	.autocomplete {
		position: relative;
		margin: 0 auto;

		&.left {
			margin: 0;
		}

		.form-input-wrapper {
			position: relative;
			cursor: text;
			min-width: 200px;

			& > img {
				top: 50%;
				transform: translateY(-50%);
				height: 50%;
				z-index: 3;
				position: absolute;
			}
		}

		.placeholder-input {
			position: absolute;
			top: 0;
			height: 100%;
			font-size: 18px;
			padding: 4px 13px;
			display: flex;
			align-items: center;
			align-self: center;
			font-weight: initial;
			color: #999;
			background-color: transparent;
			&.hidden {
				display: none;
			}
		}
		.loading {
			position: absolute;
			top: 0;
			right: 0;
			width: 30px;
			height: 100%;
		}
	}

	.items {
		top: var(--height);
		background: #fff;
		border: 2px solid #999999;
		position: absolute;
		overflow: scroll;
		width: 100%;
		border-radius: 5px;
		display: block;
		z-index: 2;

		.item {
			position: relative;
			cursor: pointer;
			padding: 12px 4px;
			line-height: 28px;
			border-bottom: 1px solid #999;
			font-weight: initial;
			text-align: left;
			:global(em) {
				font-style: normal;
				font-weight: 600;
			}
			&:last-of-type {
				border-bottom: 0;
			}

			&.hover,
			&:hover {
				// color: #fff;
				background-color: #eee;
			}

			&.icon {
				padding-left: 60px;
			}

			img {
				position: absolute;
				top: 50%;
				left: 10px;
				transform: translateY(-50%);
				height: 40px;
				margin: 0;
			}
		}

		.powered-by-google {
			img {
				margin: 5px 9px 5px 0;
				float: right;
				display: block;
				height: 16px;
			}
		}
	}

	.form-label {
		display: block;
		font-size: 14px;
		font-weight: 600;

		.label {
			display: block;
			&:first-letter {
				text-transform: uppercase;
			}
		}

		.form-input-hint {
			font-weight: initial;
		}

		&.has-success {
			color: green;
		}

		&.has-error {
			color: red;
		}

		&:not(.has-error):not(.has-success):focus-within {
			color: gray;
		}
	}

	.form-input {
		padding-top: 15px;
		padding-bottom: 15px;
		padding-left: 10px;
		margin: 10px 0;
		border-radius: 5px;
		background: #fff;
		font-size: 18px;
		appearance: none;
		border: 2px solid #999999;
		box-shadow: 0 0 5px rgba(163, 163, 163, 0.5);
		min-height: 58px;
		max-width: 100%;
		outline: none;
		position: relative;
		width: 100%;
		display: flex;
		align-items: center;
		text-align: left;
		overflow: hidden;

		&.focus {
			//border: 2px solid #4b88ce;
		}
		&::placeholder {
			color: gray;
		}

		&.has-success {
			border-color: green;
			&:focus {
				color: green;
			}
		}

		&.has-error {
			border-color: red;
			&:focus {
				color: red;
			}
		}

		&.disabled {
			background-color: yellow;
			cursor: not-allowed;
			opacity: 0.5;
		}
	}
</style>

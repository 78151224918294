<script>
	import { t } from '@woltair/translations-package-fe'
	import { get } from 'svelte/store'
	import { goto } from '$app/navigation'
	import { afterNavigate } from '$app/navigation'
	import { page } from '$app/stores'
	import { STEP } from '$lib/consts'
	import { CALL_ME } from '$lib/routes'
	import { PRIVACY_POLICY, DOCUMENTS } from '$lib/routes'
	import { Form, Frame } from './index.js'
	import * as Components from './index.js'
	import { Transition } from '../components/index.js'

	export let data = null
	export let formProps = null
	export let steps = null
	export let debug = false
	export let callButton = false
	export let sign = null
	export let cls = ''
	export let version = null
	export let actionURL = null
	export let testValueSidebar = false
	let step = $page.url.searchParams.get(STEP) ?? '0'
	afterNavigate(() => {
		step = $page.url.searchParams.get(STEP) ?? '0'
	})
	function handleCall(deviceType) {
		$page.url.searchParams.delete(STEP)
		$page.url.searchParams.set('device', deviceType)
		goto(`/${CALL_ME}${$page.url.search}`)
	}
</script>

<!-- eslint-disable-next-line @typescript-eslint/no-unused-vars -->
<div
	class="wizzard mx-auto flex-row-reverse items-stretch justify-center md:flex {testValueSidebar
		? 'max-w-7xl'
		: 'mx-auto'}"
>
	{#if testValueSidebar && step == '0'}
		<div
			class="sidebar mt-12 w-full bg-white pb-8 md:mb-14 md:rounded-br-md md:rounded-tr-md md:pt-8"
		>
			<Components.CalculatorBenefits {testValueSidebar} src="/images/icons/bulb.svg" />
		</div>
	{/if}
	<!-- eslint-disable-next-line @typescript-eslint/no-unused-vars -->
	<Frame {version} {testValueSidebar} {steps} let:step {cls}>
		{#if sign}
			<img src={sign.src} alt={sign.alt} />
		{/if}
		<Form {...formProps} {actionURL} {step} {debug} let:step success={$$slots.success}>
			<Transition key={get(step)}>
				{#if get(step) && data[get(step)]}
					{@const { components = [], buttons = [] } = data[get(step)]}
					{#each components as component}
						<svelte:component this={Components[component.this]} {...component.attrs} />
					{/each}
					{#if data[get(step)]?.conditions === 'checkbox'}
						<Components.Field
							name="conditions"
							margin="mt-4"
							type="checkbox"
							testId="conditions"
							maxWidth="500px"
						>
							<p class="subtext">
								{t('general.woltairConditions', {
									privacyLink: `/${DOCUMENTS}/${PRIVACY_POLICY}`
								})}
							</p>
						</Components.Field>
					{/if}
					<div class="mt-4 flex justify-center gap-5">
						{#each buttons as button}
							<svelte:component this={Components[button.this]} {...button?.attrs}>
								{button?.slot || ''}
							</svelte:component>
						{/each}
					</div>
					{#if ($page.data.featureConfig.meeting.hvac || $page.data.featureConfig.meeting.pv) && callButton && !data[get(step)]?.conditions && get(step) !== 'start'}
						<div class="border-top mt-3 pt-3">
							<p>{t('woltadvisor.leaveUsNumber')}</p>
							<Components.Button
								on:click={() => handleCall(callButton.deviceType)}
								secondary
								noWrap
								icon={{ src: '/images/avatars/team.webp' }}
							>
								{t('woltadvisor.callMe')}
							</Components.Button>
						</div>
					{/if}
					{#if data[get(step)]?.conditions === 'address'}
						<p class="subtext mt-2">
							{t('general.woltairConditionsAddress', {
								privacyLink: `/${DOCUMENTS}/${PRIVACY_POLICY}`
							})}
						</p>
					{/if}
					{#if data[get(step)]?.conditions === true}
						<p class="subtext mt-2">
							{t('general.woltairConditions', {
								privacyLink: `/${DOCUMENTS}/${PRIVACY_POLICY}`
							})}
						</p>
					{/if}
				{/if}
			</Transition>
			<svelte:fragment slot="success">
				<slot name="success" />
			</svelte:fragment>
		</Form>
	</Frame>
</div>

<style lang="scss">
	:global(.wizzard a) {
		@apply text-primary-500;
	}
	.border-top {
		border-top: 2px solid #cccccc;
	}

	img {
		position: absolute;
		z-index: 3;
		right: 140px;
		top: 20px;
		width: 300px;
		transform: translate(100%, -50%) rotate(15deg);

		@media (max-width: 800px) {
			display: none;
		}
	}
	.sidebar {
		box-shadow: 0 2px 4px #ababab50;
	}
</style>

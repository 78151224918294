<script>
	import { getContext } from 'svelte'
	import { key } from './key'

	export let icon = 0
	export let small = null
	export let tiny = null
	export let disablebg = null

	const { icons } = getContext(key)

	const ICONS = {
		woltair: {
			url: '/images/icons/woltair-icon-list.svg',
			alt: 'Woltair logo'
		},
		check: {
			url: '/images/icons/circle/check.svg',
			alt: 'Yes'
		},
		cross: {
			url: '/images/icons/circle/cross.svg',
			alt: 'No'
		},
		grayCheck: {
			url: '/images/icons/gray/checkmark.svg',
			alt: 'No'
		}
	}
</script>

<li class:small class:disablebg class:tiny>
	{#if icon || icons}
		<img
			src={ICONS[icon]?.url || icon?.url || ICONS[icons].url}
			alt={ICONS[icon]?.alt || icon?.alt || ICONS[icons].alt}
		/>
	{/if}
	<p><slot /></p>
</li>

<style lang="scss">
	li {
		position: relative;
		display: flex;
		min-height: 50px;
		margin: 5px 0 5px 60px;
		align-items: center;
		font-weight: 400;
		font-size: 18px;
		line-height: 30px;
		&.tiny,
		&.small {
			min-height: 35px;
			margin: 0px 0 0px 40px;

			img {
				left: -40px;
				width: 30px;
				height: 30px;
			}
		}
		&.tiny {
			padding-top: 0.3em;
			padding-bottom: 0.3em;
			p {
				line-height: 1.5em;
				font-size: 0.875rem;
			}
			img {
				width: 25px;
				height: 25px;
			}
		}

		@media only screen and (max-width: 760px) {
			flex-direction: column;
			align-items: flex-start;
			justify-content: center;
			margin: 8px 0 8px 60px;
		}

		p {
			margin: 0;
			text-align: left;
		}
		&.disablebg img {
			background: none;
		}
		img {
			position: absolute;
			background: #e20613;
			left: -60px;
			top: 50%;
			transform: translateY(-50%);
			width: 45px;
			height: 45px;
			border-radius: 999px;
			background-repeat: no-repeat;
			display: flex;
			align-items: center;
			justify-content: center;
			color: #fff;
			font-size: 26px;
			font-weight: 900;
		}
	}
</style>

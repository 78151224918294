<script>
	export let cls = ''
	export let key = null
	export let type = 'fade'
</script>

{#key key}
	{#if type === 'fly'}
		<div class="block {cls}">
			<slot />
		</div>
	{:else}
		<div class="block {cls}">
			<slot />
		</div>
	{/if}
{/key}

<style lang="scss">
	.block {
		grid-area: 1 / 1 / span 1 / span 1;

		scrollbar-width: none;

		&::-webkit-scrollbar {
			display: none;
		}
	}
</style>

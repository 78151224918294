<script>
	import { createEventDispatcher, getContext } from 'svelte'
	import { goto } from '$app/navigation'
	import { page } from '$app/stores'
	import { Icon } from '$lib/components'
	import Tooltip from '$lib/components/Tooltip.svelte'
	import { STEP } from '$lib/consts'
	import { ErrorMessage, jsonToString, storedValues } from './index.js'
	import { key } from './key.js'

	export let name = null
	export let type = 'text'
	export let buttonType = 'round'
	export let label = ''
	export let unit = ''
	export let wrap = false
	export let width = null
	export let margin = ''
	export let input = {}
	export let center = true
	export let gotoStep = null
	export let href = null
	export let hint = null
	export let cls = ''
	export let optional = false
	export let maxWidth = '350px'
	export let justify = null
	export let del = null
	export let testId = null
	export let hintTooltip = null

	export function focus() {
		inputThis.focus()
	}

	export function fill(val) {
		inputThis.value = val
	}

	let optionalVisible = false
	let inputThis

	let { form, handleChange, step, state, validateField } = getContext(key)

	const dispatch = createEventDispatcher()

	async function handleSelected() {
		dispatch('selected', $form[name])

		if (del) {
			$form[del] = null
			delete $storedValues[del]
		}

		if (gotoStep) {
			if (type === 'radio') {
				$storedValues[name] = jsonToString($form[name])
			}
			if (name) {
				await validateField(name)
				if ($state.errors[name] !== '') return
			}
			$step = gotoStep
			$page.url.searchParams.set(STEP, $step)
			goto($page.url.href)
		} else if (href) {
			goto(href)
		}
	}

	function clickOptional() {
		$form[name] = ''
		optionalVisible = !optionalVisible
	}

	function getTestId(element) {
		const clickableLabelTypes = ['radio']

		if (
			(testId && element === 'label' && clickableLabelTypes.includes(type)) ||
			(element === 'input' && !clickableLabelTypes.includes(type))
		) {
			return testId
		} else {
			return null
		}
	}

	delete $$props.testId // Prevent having both data-testid and testid atts on input
</script>

{#if optional}
	<svelte:self type="checkbox" on:click={clickOptional}>
		<p class="d-inline m-0 text-left font-normal">{optional?.text || 'Fill label text'}</p>
	</svelte:self>
{/if}
{#if !optional || optionalVisible}
	<label
		class="{unit} {type} {margin} {buttonType} {cls}"
		style:max-width={maxWidth}
		style:width
		style:justify-content={justify}
		class:unit
		class:center
		class:inline-flex={wrap}
		data-testid={getTestId('label')}
	>
		{label}
		<slot />
		<input
			{name}
			{type}
			bind:this={inputThis}
			value={name && $form[name]}
			data-testid={getTestId('input')}
			style:text-align={input.textAlign || 'left'}
			style:padding={input.padding || '15px 10px'}
			style:font-size={input.fontSize || '18px'}
			on:paste
			on:click
			on:keyup={(event) => {
				dispatch('keyup', event)
			}}
			on:change={(event) => {
				handleChange(event)
				handleSelected()
			}}
			on:blur={handleChange}
			{...$$restProps}
		/>
	</label>
	<ErrorMessage {name} {maxWidth} />
	{#if hint && !hintTooltip}
		<p class="text-gray hint mx-auto mb-2 pl-7 text-left text-sm" style:max-width={maxWidth}>
			<img src="/images/icons/hint.svg" alt="Hint" class="hint-icon" />
			{@html hint}
		</p>
	{/if}
	{#if hint && hintTooltip}
		<Tooltip opacity="opacity-90">
			<div slot="tooltip">
				{@html hintTooltip}
			</div>
			<div class="w-[300px] text-primary-500">
				<Icon width="w-4" height="h-4" src="/images/icons/circle-question.svg">
					{@html hint}
				</Icon>
			</div>
		</Tooltip>
	{/if}
{/if}

<style lang="scss">
	label {
		position: relative;
		display: block;
		text-align: left;
		padding: 0 10px;
		margin: 15px auto 8px auto;
		font-size: 16px;
		width: 100%;

		&.radio {
			border: 2px solid #cccccc;
			cursor: pointer;
			padding: 15px 10px;
			transition: 0.4s;
			text-align: center;

			&.round {
				border-radius: 999px;
			}

			&.square {
				border-radius: 5px;
			}

			&:hover {
				border: 2px solid #e20613;
				color: #e20613;
			}

			input {
				//visibility: hidden;
				display: none;
				position: absolute;
			}
		}

		&.checkbox {
			display: flex;
			flex-direction: row-reverse;
			gap: 15px;

			input {
				flex-shrink: 0;
			}
		}

		&.inline-flex {
			display: inline-flex;
			margin: 5px;
		}

		input {
			appearance: none;
			outline: none;
			margin: 3px 0 0 !important;
			border-radius: 5px;
			transition: 0.2s;
			background: #fff;
			border: 2px solid #999999;
			box-shadow: 0 0 5px rgba(163, 163, 163, 0.5);
			-webkit-appearance: none;
			width: 100%;

			&::-webkit-outer-spin-button,
			&::-webkit-inner-spin-button {
				-webkit-appearance: none;
				margin: 0;
			}

			&[type='number'] {
				appearance: textfield;
				-moz-appearance: textfield;
			}

			&[type='checkbox'] {
				transition: 0.4s background-color;
				width: 35px;
				height: 35px;
				cursor: pointer;

				&:checked {
					background: theme('colors.primary-500');
					border: theme('colors.primary-500');
					background-image: url('$static/images/icons/check.svg');
					background-repeat: no-repeat;
					background-position: center;
					background-size: 30px 30px;
				}
			}

			@media only screen and (max-width: 760px) {
				font-size: 14px;
			}

			&::placeholder {
				color: #999;
				opacity: 1;
			}
		}
	}

	:global(.unit) {
		&::after {
			color: #666666;
			font-size: 18px;
			position: absolute;
			right: 20px;
			top: 18px;
		}

		& input[type='number'] {
			text-align: right !important;
		}
	}

	.unit {
		& input[type='number'] {
			padding-right: 45px !important;
		}
	}

	.unit.CZK {
		&::after {
			content: 'Kč';
		}
	}

	.unit.MWh {
		&::after {
			content: 'MWh';
		}

		& input[type='number'] {
			padding-right: 70px !important;
		}
	}

	.unit.m2 {
		&::after {
			content: 'm²';
		}
	}

	.unit.kwh {
		&::after {
			content: 'kWh';
		}
	}

	.unit.kw {
		&::after {
			content: 'kW';
		}
	}

	.hint {
		position: relative;
		.hint-icon {
			position: absolute;
			top: 5px;
			left: 10px;
		}
	}
</style>

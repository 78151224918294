<script>
	import SignaturePad from 'signature_pad'
	import { getContext, onMount } from 'svelte'
	import { ErrorMessage } from '.'
	import { key } from './key.js'

	export const clearPad = () => {
		signaturePad.clear()
		$form[name] = ''
	}
	export let name = ''

	const { form } = getContext(key)

	let canvas
	let signaturePad

	//By adding points you increase minimal first stroke length
	const MINIMAL_SIGNATURE_POINTS = 8

	function resizeCanvas() {
		const ratio = Math.max(window.devicePixelRatio || 1, 1)
		canvas.width = canvas.offsetWidth * ratio
		canvas.height = canvas.offsetHeight * ratio
		canvas.getContext('2d').scale(ratio, ratio)
		signaturePad.clear()
	}

	onMount(() => {
		signaturePad = new SignaturePad(canvas)

		signaturePad.addEventListener('endStroke', () => {
			if (!signaturePad._data.some((data) => data.points.length >= MINIMAL_SIGNATURE_POINTS))
				signaturePad.clear()
		})
	})

	function handleChange() {
		if (!signaturePad.isEmpty()) $form[name] = signaturePad.toDataURL()
	}
</script>

<svelte:window on:resize={resizeCanvas} />

<div on:touchend={handleChange} on:mouseleave={handleChange}>
	<canvas bind:this={canvas} width="400px" height="200px" />
</div>
<ErrorMessage {name} />
<slot {clearPad} />

<style lang="scss">
	div {
		position: relative;
		width: 400px;
		height: 200px;
		-moz-user-select: none;
		-webkit-user-select: none;
		-ms-user-select: none;
		user-select: none;
		margin: 15px auto;

		@media (max-width: 760px) {
			width: 100%;
		}

		canvas {
			position: absolute;
			left: 0;
			top: 0;
			width: 400px;
			height: 200px;
			background-color: white;
			border: 2px solid #999999;
			border-radius: 5px;
			box-shadow: 0 0 5px rgba(163, 163, 163, 0.5);

			@media (max-width: 760px) {
				width: 100%;
			}
		}
	}
</style>

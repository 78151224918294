<script>
	import { getContext } from 'svelte'
	import { goto } from '$app/navigation'
	import { page } from '$app/stores'
	import { STEP } from '$lib/consts'
	import { storedValues } from './index.js'
	import { key } from './key.js'
	import { Button } from '../components/index.js'

	export let gotoStep = null
	export let text = ''
	export let href = null
	export let testId = null
	export let name = null

	let { form, step } = getContext(key)

	function handleOption() {
		if (name) {
			$form[name] = null
			delete $storedValues[name]
		}

		if (gotoStep) {
			$step = gotoStep
			$page.url.searchParams.set(STEP, $step)
			goto($page.url.href)
		} else if (href) {
			goto(href)
		}
	}
</script>

<div class="my-1">
	<Button secondary maxWidth="360px" width="100%" {testId} on:click={handleOption}>{text}</Button>
</div>

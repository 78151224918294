<script>
	import { setContext } from 'svelte'
	import { key } from './key'

	export let margin = null
	export let icons = 'woltair'

	setContext(key, {
		icons
	})
</script>

<ul class={margin}>
	<slot />
</ul>

<style lang="scss">
	ul {
		list-style-type: none;
		margin: 30px 0;
		padding-left: 0;
	}
</style>

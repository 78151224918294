<script>
	import { getContext } from 'svelte'
	import { goto } from '$app/navigation'
	import { page } from '$app/stores'
	import { STEP } from '$lib/consts'
	import { key } from './key.js'
	import { Button } from '../components/index.js'

	export let gotoStep = '0'

	const { step } = getContext(key)

	function prev() {
		$step = gotoStep
		$page.url.searchParams.set(STEP, $step)
		goto($page.url.href)
	}
</script>

<Button
	on:click={prev}
	tertiary
	icon={{ src: '/images/icons/arrow.svg', alt: 'Šipka zpět', marginLeft: '0' }}
>
	<slot />
</Button>

<!-- </Tooltip> -->
<script>
	export let bg = 'bg-neutral-900'
	export let opacity = 'opacity-60'
	let display = 'hidden'
	let handleMOuseOver = () => {
		display = 'block'
	}
	let handleMOuseOut = () => {
		display = 'hidden'
	}
</script>

<!-- Description -->
<!-- Tooltip component -->
<!-- Usage -->
<!-- <Tooltip> -->
<!-- <span>Hover me</span> -->
<!-- <div slot="tooltip">Tooltip text</div> -->

<div
	class="tooltipped relative inline-block"
	on:mouseover={handleMOuseOver}
	on:focus={handleMOuseOver}
	on:mouseout={handleMOuseOut}
	on:blur={handleMOuseOut}
>
	<slot />
	<div class="tooltip absolute left-1/2 w-60 -translate-x-1/2 {display}">
		<div class="tooltip_bg {opacity} absolute -left-4 -top-4 border border-neutral-500 {bg} ">
			<div
				class="tooltip_arrow absolute bottom-0 left-1/2 h-4 w-4 -translate-x-1/2 translate-y-1/2 rotate-45 border border-b-neutral-500 border-l-transparent border-r-neutral-500 border-t-transparent {bg}"
			/>
		</div>
		<div class="tooltiptext relative z-10 text-center text-white">
			<slot name="tooltip" />
		</div>
	</div>
</div>

<style>
	.tooltip {
		bottom: calc(100% + 2em);
	}
	.tooltip_bg {
		width: calc(100% + 2em);
		height: calc(100% + 2em);
		border-radius: 0.5em;
	}
</style>

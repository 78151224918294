<script>
	import { fade } from 'svelte/transition'
	import { Field } from './index.js'

	export let name = ''
	export let label = ''
	export let inputs = []
	export let gotoStep
	export let type
	export let testId = null

	let open = false
</script>

<div class="wrapper">
	<Field type="radio" on:click={() => (open = !open)} {testId}>
		{label}
	</Field>
	{#if open}
		<div class="gap-15 flex" transition:fade>
			{#each inputs as { label, value, testId }}
				<Field {type} {name} {label} {value} {gotoStep} {testId} />
			{/each}
		</div>
		<div in:fade class="mt-1 border" />
	{/if}
</div>

<style lang="scss">
	.wrapper {
		max-width: 350px;
		margin: 20px auto;

		.border {
			margin-left: 5%;
			width: 90%;
			border-bottom: 2px solid #cccccc;
		}
	}
</style>

<script context="module">
	export async function createLocation({ source, locationId }) {
		try {
			// Fetch location data
			let response = await fetch('/api/map/locationParse', {
				method: 'POST',
				body: JSON.stringify({
					source,
					locationId
				})
			})

			// Check if the fetch call was successful
			if (!response.ok) {
				throw new Error(`Failed to fetch location data with status: ${response.status}`)
			}

			const locationData = await response.json()
			const streetAndNumber =
				locationData.street && locationData.number
					? locationData.street + ' ' + locationData.number
					: false

			// Fetch location creation
			response = await fetch('/api/map/create-location', {
				method: 'POST',
				body: JSON.stringify({
					fullAddress: {
						street: streetAndNumber || 'unknown',
						city: locationData.city,
						region: locationData.region,
						zip: locationData.zip || 'unknown',
						country: locationData.country
					}
				})
			})
			// Check if the fetch call was successful
			if (!response.ok) {
				throw new Error(`Failed to create location with status: ${response.status}`)
			}

			const { locationCreate, error } = await response.json()

			return {
				addressID: locationCreate?.address?.id,
				locationID: locationCreate?.id,
				region: locationData.region,
				error
			}
		} catch (err) {
			console.error('Error in createLocation:', err)
			return {
				error: err.message
			}
		}
	}
</script>

<script>
	import { t } from '@woltair/translations-package-fe'
	import { getContext } from 'svelte'
	import { goto } from '$app/navigation'
	import { page } from '$app/stores'
	import { STEP } from '$lib/consts'
	import { Field, storedValues } from './index.js'
	import { key } from './key.js'
	import { Autocomplete } from '../components/index.js'

	export let error = null
	export let label = null
	export let disabled = false
	export let cls = ''
	export let width = '100%'
	export let maxWidth = '600px'
	export let left = false
	export let incompleteAddress = false

	export let gotoStep = '0'
	export let testId = null

	let { form, step, errors } = getContext(key)

	async function getSuggestion(query) {
		const response = await fetch('/api/map/locationSuggest', {
			method: 'POST',
			body: JSON.stringify(query),
			headers: { accept: 'application/json' }
		})

		if (!response.ok) {
			throw new Error('Failed to fetch region from zip.')
		}

		return await response.json()
	}

	async function handleSelected(values) {
		if (!values.fullAddressMatched) {
			$errors.location = t('address.incompleteAddress')
			return
		}

		const { addressID, locationID, region } = await createLocation(values)

		$form.location = {
			fullAddressMatched: values.fullAddressMatched,
			id: locationID,
			address: {
				name: values.formattedText,
				id: addressID
			}
		}
		if (region) {
			$form.regionName = $storedValues['regionName'] = region
		}
		$errors.location = ''
		$storedValues.location = $form.location
		if (gotoStep) {
			$step = gotoStep
			$page.url.searchParams.set(STEP, $step)
			goto($page.url.href)
		}
	}
</script>

<Autocomplete
	{label}
	placeholder={$form.locationUnknown
		? t('web.forms.address.city')
		: t('web.forms.address.streetAndNumber')}
	{error}
	name="location"
	{disabled}
	{cls}
	{width}
	{left}
	{maxWidth}
	items={getSuggestion}
	bind:value={$form['location']}
	let:item
	icon={{ src: '/images/icons/icon-search.svg', alt: 'Lupa' }}
	paddingRight="10px"
	on:selected={(event) => handleSelected(event.detail)}
	{testId}
>
	<div slot="selected" class="slot">
		{#if item?.address?.name || item?.formattedText}
			<div class="selectedItem text-neutral-800" data-testid={testId}>
				{item.address?.name || item.formattedText}
			</div>
		{/if}
	</div>
</Autocomplete>

{#if incompleteAddress}
	<Field
		type="checkbox"
		checked={$form.locationUnknown}
		name="locationUnknown"
		maxWidth="600px"
		justify="flex-end"
		testId={testId ? `${testId}-locationUnknown` : null}
		on:selected={() => {
			$storedValues.locationUnknown = $form.locationUnknown
		}}
	>
		<p class="text-normal">Nemám přesnou adresu. Chci zadat pouze město.</p>
	</Field>
{/if}

<style>
	.slot {
		padding-right: 40px;
		width: 100%;
	}

	.selectedItem {
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}
</style>

<script>
	import { getContext } from 'svelte'
	import { key } from './key.js'
	import { Transition } from '../components/index.js'

	export let value

	let { step } = getContext(key)
</script>

{#if $step === value}
	<Transition key={$step}>
		<slot />
	</Transition>
{/if}

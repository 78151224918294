<script context="module">
	export function buildAddress(addressDetail, coords) {
		const street = getAddressComponent(addressDetail, ADDRESS_KEY.STREET)
		const streetNumber = getAddressComponent(addressDetail, ADDRESS_KEY.STREET_NUMBER)

		if (streetNumber)
			return {
				street: `${street} ${streetNumber}`,
				city: getAddressComponent(addressDetail, ADDRESS_KEY.CITY),
				zip: getAddressComponent(addressDetail, ADDRESS_KEY.ZIP),
				country: getAddressComponent(addressDetail, ADDRESS_KEY.COUNTRY),
				gpsLat: coords?.lat || addressDetail.geometry.location.lat,
				gpsLng: coords?.lng || addressDetail.geometry.location.lng
			}
		else if (!streetNumber && coords)
			return {
				street: street || '',
				city: getAddressComponent(addressDetail, ADDRESS_KEY.CITY) || '',
				zip: getAddressComponent(addressDetail, ADDRESS_KEY.ZIP) || '',
				country: getAddressComponent(addressDetail, ADDRESS_KEY.COUNTRY),
				gpsLat: coords?.lat || addressDetail.geometry.location.lat,
				gpsLng: coords?.lng || addressDetail.geometry.location.lng
			}
		else return null
	}

	export async function createLocation(fullAddress) {
		const resLocation = await fetch('/api/map/create-location', {
			method: 'POST',
			body: JSON.stringify({
				fullAddress
			})
		})

		const { locationCreate, error } = await resLocation.json()

		return {
			addressID: locationCreate?.address?.id,
			locationID: locationCreate?.id,
			error
		}
	}
</script>

<script>
	import { t } from '@woltair/translations-package-fe'
	import { getContext } from 'svelte'
	import { goto } from '$app/navigation'
	import { page } from '$app/stores'
	import { ADDRESS_KEY } from '$lib/consts'
	import { STEP } from '$lib/consts'
	import { autocompleteAddress, getLocationFromAddress } from '$lib/utils'
	import { getAddressComponent } from '$lib/utils'
	import { storedValues } from './index.js'
	import { key } from './key.js'
	import { Autocomplete } from '../components/index.js'

	export let error = null
	export let label = null
	export let disabled = false
	export let placeholder = ''
	export let cls = ''
	export let width = '100%'
	export let maxWidth = '600px'
	export let left = false
	export let regionName = false
	export let testId = null

	export let gotoStep = '0'
	export let gotoStepMap = '0'

	let { form, step, errors } = getContext(key)

	async function search(search) {
		const searchString = search.trim()
		if (searchString === '') return []

		try {
			const hints = await autocompleteAddress(search, {
				restrictions: { country: $page.data.country },
				lang: $page.data.lang
			})

			return [
				...hints.map((hint) => ({
					name: hint.description.split(',').slice(0, -1),
					placeId: hint.place_id,
					icon: {
						src: '/images/icons/circle/marker.svg',
						alt: t('general.map')
					}
				})),
				{
					name: t('address.cantFindAddress'),
					testId: testId ? testId + '-cantFindAddress' : null,
					icon: {
						src: '/images/icons/circle/map.svg',
						alt: t('general.map')
					}
				}
			]
		} catch (err) {
			console.error(err)
		}
	}

	async function handleSelected(value) {
		if (value.placeId) {
			const { results } = await getLocationFromAddress({ placeId: value.placeId })
			const [addressDetail] = results

			if (regionName) {
				$form.regionName = getAddressComponent(addressDetail, ADDRESS_KEY.REGION)
				$storedValues['regionName'] = $form.regionName
			}

			const payload = buildAddress(addressDetail)

			if (!payload) {
				const message = t('address.incompleteAddress')
				$errors['location'] = message
				return
			}

			const { addressID, locationID } = await createLocation(payload)

			$form['location'] = {
				id: locationID,
				address: {
					id: addressID,
					name: value.name
				}
			}

			$storedValues['location'] = $form['location']
		}

		$step = value.placeId ? gotoStep : gotoStepMap
		$page.url.searchParams.set(STEP, $step)
		$errors['location'] = ''
		goto($page.url.href)
	}
</script>

<Autocomplete
	{label}
	{placeholder}
	{error}
	name="location"
	{disabled}
	{cls}
	{width}
	{left}
	{maxWidth}
	{testId}
	googleLogo
	items={search}
	bind:value={$form['location']}
	let:item
	icon={{ src: '/images/icons/icon-search.svg', alt: 'Lupa' }}
	paddingRight="10px"
	on:selected={(event) => handleSelected(event.detail)}
>
	<div slot="selected" class="slot">
		{#if item?.placeId || item?.id}
			<div class="selectedItem" data-testid={testId ? `${testId}-selected` : null}>
				{item?.address?.name || item.name}
			</div>
		{/if}
	</div>
</Autocomplete>

<style>
	.slot {
		padding-right: 40px;
		width: 100%;
	}

	.selectedItem {
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}
</style>

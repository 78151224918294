<script>
	export let tag = null
	export let margin = null
	export let color = null
	export let content = null
	export let src = null
	export let alt = null
	export let width = ''
	export let maxWidth = ''
	export let height = null
	export let cls = ''
</script>

{#if tag === 'img'}
	<img {src} {alt} style:height style:width style:max-width={maxWidth} class={cls} />
{:else}
	<svelte:element this={tag} class="{margin} {cls}" style:color>{@html content}</svelte:element>
{/if}

<script>
	import { t } from '@woltair/translations-package-fe'
	import { setContext } from 'svelte'
	import { writable } from 'svelte/store'
	import { fade } from 'svelte/transition'
	import { createForm } from 'svelte-forms-lib'
	import { number, object, string } from 'yup'
	import { goto } from '$app/navigation'
	import { page } from '$app/stores'
	import { sendGTM } from '$lib/analytics'
	import { storedValues } from './index.js'
	import { key } from './key.js'
	import { Loading, Transition } from '../components/index.js'

	export let buttonLoader = false
	export let initialValues = {}
	export let step = null
	export let storedInitialValues = {}
	export let validate = null
	export let resolve = true
	export let validationSchema = {}
	export let cls = ''
	export let actionURL = null
	export let submitFunction = async (values) => {
		const res = await fetch(actionURL || $page.url.pathname, {
			method: 'POST',
			body: JSON.stringify({
				values
			}),
			headers: {
				accept: 'application/json'
			}
		})

		const { analytics, redirect, error = t('customer.details.updateError') } = await res.json()

		if (!res.ok) throw new Error(error)

		if (analytics) sendGTM(analytics.event, { id: analytics.id, item: analytics.item })

		if (redirect) goto(redirect)
		return {}
	}

	const promise = writable(null)
	let initValues = {}

	Object.keys(storedInitialValues).forEach((key) => {
		if ($storedValues[key] && (key !== 'location' || $storedValues[key]?.address?.name)) {
			storedInitialValues[key] = $storedValues[key]
		}
	})

	initValues = { ...storedInitialValues, ...initialValues }

	let onSubmit = (values) => {
		Object.keys($form).forEach((key) => {
			if (Object.hasOwn(storedInitialValues, key)) $storedValues[key] = $form[key]
		})
		$promise = submitFunction(values)
	}

	const validationPatterns = {
		phone: number()
			.required(t('forms.validations.phone.required'))
			.typeError(t('forms.validations.phone.typeError'))
			.min(100000000, t('forms.validations.phone.format')),
		email: string()
			.email(t('forms.validations.email.format'))
			.required(t('forms.validations.email.required')),
		personName: string().required(t('forms.validations.name.required')),
		location: object()
			.nullable()
			.test('location', t('address.incompleteAddress'), (value) =>
				(Object.hasOwn($form, 'locationUnknown') ? $form.locationUnknown : true)
					? value?.address?.name && value?.address?.id && value?.id
					: value?.address?.name && value?.address?.id && value?.address?.full && value?.id
			)
			.required()
	}

	Object.keys(validationSchema).forEach((key) => {
		const match = Object.keys(validationPatterns).find(
			(pattern) => key === pattern && !validationSchema[key]
		)
		if (match) validationSchema[match] = validationPatterns[match]
	})

	export let context = createForm({
		initialValues: initValues,
		onSubmit,
		validate,
		...(Object.keys(validationSchema).length && {
			validationSchema: object().shape({
				...validationSchema
			})
		})
	})

	const {
		form,
		errors,
		touched,
		state,
		handleChange,
		handleSubmit,
		updateField,
		updateInitialValues,
		updateTouched,
		updateValidateField,
		validateField
	} = context

	setContext(key, {
		form,
		errors,
		promise,
		touched,
		state,
		handleChange,
		handleSubmit,
		updateField,
		updateInitialValues,
		updateTouched,
		updateValidateField,
		validateField,
		step
	})
</script>

{#if buttonLoader}
	<div class="grid {cls}">
		<Transition key={$step}>
			<form on:submit={handleSubmit} {...$$restProps}>
				<slot
					{form}
					{errors}
					{touched}
					{promise}
					{state}
					{handleChange}
					{handleSubmit}
					{updateField}
					{updateInitialValues}
					{updateTouched}
					{updateValidateField}
					{validateField}
					{step}
				/>
			</form>
		</Transition>
	</div>
	{#await $promise}
		<div />
	{:then value}
		{#if value && $$slots.success}
			<div class="toast gap-15 align-center my-5 flex items-center justify-between gap-4" in:fade>
				<img src="/images/icons/circle/checklist.svg" alt="Odesláno" class="w-16" />
				<p class="my-0">
					<slot name="success" />
				</p>
				<span role="presentation" on:click={() => ($promise = null)}>✖</span>
			</div>
		{/if}
	{:catch error}
		<div class="toast gap-15 align-center my-5 flex justify-center" in:fade>
			<p class="my-0">
				{error.message}
			</p>
			<span role="presentation" on:click={() => ($promise = null)}>✖</span>
		</div>
	{/await}
{:else}
	<div class="grid {cls}">
		{#if resolve && $promise}
			<Transition>
				<div class="align-center grid justify-center">
					{#await $promise}
						<Transition>
							<div class="loading container-flex align-center justify-center">
								<Loading type="secondary" />
							</div>
						</Transition>
					{:then _value}
						<Transition>
							{#if $$slots.success}
								<slot name="success" />
							{:else}
								<div class="loading container-flex align-center justify-center">
									<Loading type="secondary" />
								</div>
							{/if}
						</Transition>
					{:catch error}
						<Transition>
							<div class="center">
								<img width="65%" src="/images/illustrations/woltair-house.svg" alt="" />
								<h4 class="center">{@html error.message}</h4>
							</div>
						</Transition>
					{/await}
					<div class="grid" />
				</div>
			</Transition>
		{:else}
			<Transition key={$step}>
				<form on:submit={handleSubmit} {...$$restProps}>
					<slot
						{form}
						{errors}
						{touched}
						{promise}
						{state}
						{handleChange}
						{handleSubmit}
						{updateField}
						{updateInitialValues}
						{updateTouched}
						{updateValidateField}
						{validateField}
						{step}
					/>
				</form>
			</Transition>
		{/if}
	</div>
{/if}

<style lang="scss">
	.grid {
		grid-template-columns: 100%;
	}

	form {
		text-align: center;
	}

	h4 {
		max-width: 75%;
		margin: 15px auto 0;
	}

	.loading {
		min-height: 400px;
		display: flex;
		align-self: center;
		justify-content: center;
	}

	.toast {
		z-index: 999;
		position: fixed;
		left: 50%;
		bottom: 30px;
		transform: translate(-50%, 50%);
		background: #ffffff;
		border-radius: 10px;
		box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.175);
		padding: 15px;
		width: calc(100% - 30px);
		max-width: 400px;
		border: 2px solid #e20613;

		@media (max-width: 760px) {
			bottom: 15px;
		}

		span {
			cursor: pointer;
			font-size: 22px;
		}
	}
</style>

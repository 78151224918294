<script>
	import { getContext } from 'svelte'
	import { ErrorMessage } from './index.js'
	import { key } from './key.js'

	export let name
	export let label = ''
	export let options = null
	export let placeholder = null
	export let testId = null

	const { form, handleChange } = getContext(key)
</script>

<label>
	{label}
	<div>
		<img class="arrow" src="/images/icons/arrow-down.svg" alt="" height="12px" />
		<select
			{name}
			value={$form[name]}
			data-testid={testId}
			on:change={handleChange}
			on:blur={handleChange}
			{...$$restProps}
		>
			<slot />
			{#if placeholder}
				<option disabled hidden selected value="">{placeholder}</option>
			{/if}
			{#if options}
				{#each options as { value, name }}
					<option {value}>{name}</option>
				{/each}
			{/if}
		</select>
	</div>
	<ErrorMessage {name} />
</label>

<style lang="scss">
	label {
		position: relative;
		display: block;
		max-width: 350px;
		text-align: left;
		padding: 0 10px;
		margin: 15px auto 8px auto;
		font-size: 16px;
		width: 100%;

		div {
			position: relative;

			.arrow {
				position: absolute;
				display: block;
				top: 50%;
				transform: translateY(-35%);
				right: 17px;
			}

			select {
				font-size: 18px;
				appearance: none;
				outline: none;
				margin: 3px 0 0 !important;
				padding: 15px 10px;
				border-radius: 5px;
				transition: 0.2s;
				background: #fff;
				border: 2px solid #999999;
				box-shadow: 0 0 5px rgba(163, 163, 163, 0.5);
				-webkit-appearance: none;
				width: 100%;

				&::-webkit-outer-spin-button,
				&::-webkit-inner-spin-button {
					-webkit-appearance: none;
					margin: 0;
				}

				@media only screen and (max-width: 760px) {
					font-size: 14px;
				}

				&::placeholder {
					color: #999;
					opacity: 1;
				}
			}
		}
	}
</style>

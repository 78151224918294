<script lang="ts">
	import Icon from '$lib/components/Icon.svelte'
	export let testValueSidebar: boolean = false
	export let src: string = '/images/icons/calculator.svg'
</script>

<div
	class="box {testValueSidebar
		? 'text-ceter bg-white p-12 pt-0 text-xl md:pt-6 md:text-left'
		: 'bg-neutral-100 p-12 pt-6'}"
>
	<div
		class="justify-left mb-4 {testValueSidebar ? 'text-center md:text-left' : 'flex items-center'}"
	>
		<img
			{src}
			class="inline-block
			{testValueSidebar ? '-mt-8 h-14 w-14 md:mt-0 md:h-10 md:w-10' : 'h-10 w-10'}"
			alt=""
		/>
		<h3 class="unstyled h4 my-4 w-full {testValueSidebar ? 'my-8' : 'pl-4'}  font-bold">
			Kalkulačka ceny a úspory
		</h3>
	</div>
	<p class="mb-4 {testValueSidebar ? 'text-center md:text-left' : 'pl-4'}">
		<strong>Vyplníte za 2 min</strong> a okamžitě se online dozvíte:
	</p>
	<Icon
		wrapcls="my-2"
		src="/images/icons/circle-check.svg"
		cls="transition-all cursor-pointer rotateme"
		margin="my-2 mr-3"
		width="h-5"
		height="h-5">Vyplatí se vám tepelné čerpadlo?</Icon
	>
	<Icon
		wrapcls="my-2"
		src="/images/icons/circle-check.svg"
		cls="transition-all cursor-pointer rotateme"
		margin="my-2 mr-3"
		width="h-5"
		height="h-5">Roční úspora na vytápění a ohřev teplé vody pro váš dům</Icon
	>
	<Icon
		wrapcls="my-2"
		src="/images/icons/circle-check.svg"
		cls="transition-all cursor-pointer rotateme"
		margin="my-2 mr-3"
		width="h-5"
		height="h-5">Za jak dlouho se vám vrátí investice do tepelného čerpadla</Icon
	>
	<Icon
		wrapcls="my-2"
		src="/images/icons/circle-check.svg"
		cls="transition-all cursor-pointer rotateme"
		margin="my-2 mr-3"
		width="h-5"
		height="h-5">4 varianty tepelných čerpadel vhodné pro váš případ</Icon
	>
	<Icon
		wrapcls="my-2"
		src="/images/icons/circle-check.svg"
		cls="transition-all cursor-pointer rotateme"
		margin="my-2 mr-3"
		width="h-5"
		height="h-5">Kompletní cena tepelného čerpadla včetně práce a materiálu</Icon
	>
	<p class="mt-6 {testValueSidebar ? 'text-center md:text-left' : 'text-center'} font-black">
		Využití kalkulačky je zdarma a k ničemu vás nezavazuje.
	</p>
</div>

<style>
	.box {
		@apply -mb-6 rounded-2xl text-left;
	}
</style>

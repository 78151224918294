<script>
	import { dayjs } from '@woltair/translations-package-fe'
	import { t } from '@woltair/translations-package-fe'
	import dayjsBusinessTime from 'dayjs-business-time'
	import { getContext } from 'svelte'
	import { goto } from '$app/navigation'
	import { page } from '$app/stores'
	import { MEETINGS_MIN_BUSINESS_DAYS_FROM_NOW, MEETING_TIME } from '$lib/consts'
	import { STEP } from '$lib/consts'
	import { Button, Transition } from '$lib/ui-woltair/components'
	import { Field } from '$lib/ui-woltair/form'
	import { storedValues } from './index.js'
	import { key } from './key.js'

	export let gotoStep

	dayjs.extend(dayjsBusinessTime)

	let { form } = getContext(key)

	export let name = null

	let pickTerm = false

	let firstOpenTerm = dayjs().addBusinessDays(MEETINGS_MIN_BUSINESS_DAYS_FROM_NOW).startOf('day')
	let start = firstOpenTerm.startOf('week')
	let end = firstOpenTerm.endOf('week')
	let selectedDay = firstOpenTerm

	function prevWeek() {
		start = start.subtract(1, 'week')
		end = end.subtract(1, 'week')
		if (start.isBefore(firstOpenTerm)) selectedDay = firstOpenTerm
		else selectedDay = start
	}

	function nextWeek() {
		start = start.add(1, 'week')
		end = end.add(1, 'week')
		selectedDay = start
	}

	function pickDate(date) {
		if (selectedDay !== date.toISOString()) {
			selectedDay = date
		}
	}

	function handleASAP() {
		delete $storedValues[name]
		$form[name] = ''
		$page.url.searchParams.set(STEP, gotoStep)
		goto($page.url.href)
	}
</script>

{#if !pickTerm}
	<div class="mx-auto flex max-w-[300px] flex-col gap-5">
		<Button on:click={handleASAP} secondary>{t('woltadvisor.preferedTime.ASAP')}</Button>
		<Button on:click={() => (pickTerm = true)} secondary
			>{t('woltadvisor.preferedTime.pickDate')}</Button
		>
	</div>
{:else}
	<section>
		<div class="align-center gap-30 mb-2 flex justify-center">
			<Button
				disabled={start.subtract(1, 'week').isBefore(firstOpenTerm.startOf('week'))}
				round
				tertiary
				on:click={prevWeek}
				icon={{ src: '/images/icons/arrow/prev-dark.svg', alt: 'Šipka', marginLeft: '3px' }}
			/>
			<div class="grid">
				<Transition key={start}>
					<p class="min-width-300 min-width-full-m d-block mb-0 mt-0">
						{start.format('DD. MMMM')} -
						<br class="only-mobile" />
						{end.format('DD. MMMM YYYY')}
					</p>
				</Transition>
			</div>
			<Button
				round
				tertiary
				on:click={nextWeek}
				icon={{ src: '/images/icons/arrow/next-dark.svg', alt: 'Šipka', marginLeft: '4px' }}
			/>
		</div>
		<div class="grid">
			<Transition key={start} type="fly">
				<div class="mb-3 flex justify-center gap-5">
					{#each Array(7) as _, index}
						{@const date = start.add(index, 'days')}
						{@const selected = date.toISOString() === selectedDay.toISOString()}
						<div>
							<p class="first-cap mb-0 mt-0">{date.format('dd')}</p>
							<Button
								round
								disabled={!date.isBusinessDay() || date < firstOpenTerm}
								transparent={!selected}
								primary={selected}
								hover={!selected}
								wrap
								on:click={() => pickDate(date)}
							>
								{date.format('D.')}
							</Button>
						</div>
					{/each}
				</div>
			</Transition>
		</div>
		<div class="grid text-center">
			{#key selectedDay}
				<Transition cls="mx-auto max-width-400">
					<div class="column justify-center-m flex gap-5">
						{#each MEETING_TIME as { text, from, to }}
							<Field
								cls="text-nowrap"
								margin="m-0 px-5 px-m-1"
								{name}
								type="radio"
								center={false}
								value="[{selectedDay.set('hours', from)}, {selectedDay.set('hours', to)}]"
								buttonType="square"
								{gotoStep}
							>
								{t(`MEETING_TIME.${text}`)} ({from}:00 - {to}:00)
							</Field>
						{/each}
					</div>
				</Transition>
			{/key}
		</div>
	</section>
{/if}

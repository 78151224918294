<script>
	import { getContext } from 'svelte'
	import { key } from './key.js'

	export let name
	export let maxWidth = '350px'

	const { errors } = getContext(key)
</script>

{#if $errors[name]}
	<small style:max-width={maxWidth} {...$$restProps}>
		{$errors[name]}
	</small>
{/if}

<style lang="scss">
	small {
		display: block;
		margin: 0 auto;
		padding-left: 15px;
		color: theme('colors.primary-500');
		font-size: 16px;
		font-weight: 400;
		text-align: left;
		margin-top: 5px;
	}
</style>

<script>
	import { getContext } from 'svelte'
	import { goto } from '$app/navigation'
	import { page } from '$app/stores'
	import { STEP } from '$lib/consts'
	import { storedValues } from './index.js'
	import { key } from './key.js'
	import { Button } from '../components/index.js'

	export let text = ''
	export let name = null
	export let gotoStep = '0'
	export let testId = null

	const { form, validateField, state, step } = getContext(key)

	async function next() {
		if (name) {
			await validateField(name)
			if ($state.errors[name] !== '') return
		}

		$step = gotoStep
		$storedValues[name] = $form[name]
		$page.url.searchParams.set(STEP, $step)
		goto($page.url.href)
	}
</script>

<Button on:click={next} {testId}>
	<div class="flex items-center gap-2">
		{text}
		<slot />
		<img src="/images/icons/arrow-next.svg" alt="Šipka dopředu" class="ml-1 inline-block" />
	</div>
</Button>

<style lang="scss">
	img {
		height: 16px;
		width: auto;
	}
</style>

<script context="module">
	export async function createLocation({ STREET_AND_NUMBER, CITY, ZIP }) {
		const resLocation = await fetch('/api/map/create-location', {
			method: 'POST',
			body: JSON.stringify({
				fullAddress: {
					street: STREET_AND_NUMBER || 'unknown',
					city: CITY,
					zip: ZIP || 'unknown',
					country: 'CZ'
				}
			})
		})

		const { locationCreate, error } = await resLocation.json()

		return {
			addressID: locationCreate?.address?.id,
			locationID: locationCreate?.id,
			error
		}
	}
</script>

<script>
	import { t } from '@woltair/translations-package-fe'
	import { getContext } from 'svelte'
	import { goto } from '$app/navigation'
	import { page } from '$app/stores'
	import { STEP } from '$lib/consts'
	import { Field, storedValues } from './index.js'
	import { key } from './key.js'
	import { Autocomplete } from '../components/index.js'

	export let error = null
	export let label = null
	export let disabled = false
	export let cls = ''
	export let width = '100%'
	export let maxWidth = '600px'
	export let left = false
	export let regionName = false
	export let incompleteAddress = true

	export let gotoStep = '0'
	export let testId = null

	let { form, step, errors } = getContext(key)

	async function search(addressString) {
		const address = addressString.trim()
		if (address === '') return []

		const res = await fetch(
			`/api/map/location-cz?address=${address}${$form.locationUnknown ? '&type=CITY' : ''}`
		)

		return await res.json()
	}

	async function handleSelected(values) {
		if (regionName && !$form.locationUnknown) {
			$form.regionName = values.REGION
			$storedValues['regionName'] = $form.regionName
		} else if ($form.locationUnknown) {
			$form.regionName = ''
			$storedValues.regionName = $form.regionName
			$storedValues.locationUnknown = $form.locationUnknown
		}

		if (!values.NUMBER && !$form.locationUnknown) {
			const message = t('address.incompleteAddress')
			$errors['location'] = message
			return
		}

		const { addressID, locationID } = await createLocation(values)

		$form['location'] = {
			id: locationID,
			address: {
				...(!$form.locationUnknown && { full: true }),
				id: addressID,
				name: values?.WHOLE_ADDRESS || values.CITY
			}
		}
		$errors['location'] = ''

		$storedValues['location'] = $form['location']

		$step = gotoStep
		$page.url.searchParams.set(STEP, $step)
		goto($page.url.href)
	}
</script>

<Autocomplete
	{label}
	placeholder={$form.locationUnknown
		? t('web.forms.address.city')
		: t('web.forms.address.streetAndNumber')}
	{error}
	name="location"
	{disabled}
	{cls}
	{width}
	{left}
	{maxWidth}
	items={search}
	bind:value={$form['location']}
	let:item
	icon={{ src: '/images/icons/icon-search.svg', alt: 'Lupa' }}
	paddingRight="10px"
	on:selected={(event) => handleSelected(event.detail)}
	{testId}
>
	<div slot="selected" class="slot">
		{#if item?.WHOLE_ADDRESS || item?.CITY || item?.id}
			<div class="selectedItem text-neutral-800" data-testid={testId}>
				{item?.WHOLE_ADDRESS || item?.CITY || item.address.name}
			</div>
		{/if}
	</div>
</Autocomplete>
{#if incompleteAddress}
	<Field
		type="checkbox"
		checked={$form.locationUnknown}
		name="locationUnknown"
		maxWidth="600px"
		justify="flex-end"
		testId={testId ? `${testId}-locationUnknown` : null}
		on:selected={() => {
			$storedValues.locationUnknown = $form.locationUnknown
		}}
	>
		<p class="text-normal">Nemám přesnou adresu. Chci zadat pouze město.</p>
	</Field>
{/if}

<style>
	.slot {
		padding-right: 40px;
		width: 100%;
	}

	.selectedItem {
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
	}
</style>

<script>
	import { getContext } from 'svelte'
	import { ErrorMessage } from '.'
	import { key } from './key.js'

	export let name
	export let label = ''
	export let testId = null

	const { form, handleChange } = getContext(key)
</script>

<label class="subtext">
	{label}
	<slot />
	<textarea
		{name}
		data-testid={testId}
		on:change={handleChange}
		on:blur={handleChange}
		{...$$restProps}
	>
		{$form[name]}
	</textarea>
</label>
<ErrorMessage {name} />

<style lang="scss">
	label {
		display: block;
		max-width: 350px;
		text-align: left;
		padding: 0 10px;
		margin: 15px auto 8px auto;
		font-size: 16px;
		width: 100%;

		textarea {
			font-size: 18px;
			appearance: none;
			outline: none;
			margin: 3px 0 0 !important;
			padding: 15px 10px;
			border-radius: 5px;
			transition: 0.2s;
			background: #fff;
			border: 2px solid #999999;
			box-shadow: 0 0 5px rgba(163, 163, 163, 0.5);
			-webkit-appearance: none;
			width: 100%;
		}
	}
</style>

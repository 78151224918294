<script>
	import { t } from '@woltair/translations-package-fe'
	import { writable } from 'svelte/store'
	import { afterNavigate } from '$app/navigation'
	import { page } from '$app/stores'
	import { STEP } from '$lib/consts'
	import { Transition } from '$lib/ui-woltair/components'

	export let steps = null
	export let cls = ''
	export let version = null
	export let testValueSidebar = false //AB test sidebar ww-109

	export const step = writable($page.url.searchParams.get(STEP) || '0')

	afterNavigate(({ from, to }) => {
		if ($step && from?.url.pathname === to.url.pathname)
			$step = $page.url.searchParams.get(STEP) || '0'
	})

	function delLetters(value) {
		return value.replace(/[a-z]+/g, '') || value
	}
</script>

<div
	class="frame {cls} mb-14 mt-12 {testValueSidebar && $step == '0'
		? 'rounded-bl-md rounded-tl-md'
		: 'rounded-md'}"
>
	<div class="grid {testValueSidebar && $step == '0' ? '' : 'rounded-tl-md rounded-tr-md'} ">
		{#if $step !== 'start'}
			{#if steps && delLetters($step) !== steps}
				<div class="dashes">
					{#each Array(steps - 1) as _}
						<div class="dash" />
					{/each}
				</div>
			{/if}
			<div class="bar" style:width="{steps ? (100 / steps) * delLetters($step) : 100}%" />
		{:else}
			<div class="bar" style="width: 100%" />
		{/if}
	</div>
	{#if steps && $step !== 'start' && delLetters($step) !== steps && version !== '1'}
		<Transition>
			<p class="steps">
				{t('web.forms.step')}
				{Math.floor(Number(delLetters($step)) + 1)} / {steps}
			</p>
		</Transition>
	{/if}
	<slot {step} />
</div>

<style lang="scss">
	.frame {
		position: relative;
		box-shadow: 0 2px 4px #ababab50;
		background: #ffffff;
		padding: 50px 75px;
		max-width: 800px;
		width: 100%;

		&.transparent {
			background: transparent;
			box-shadow: none;
			border: none;
			margin: 0 auto;

			.grid {
				display: none;
			}
		}

		@media only screen and (max-width: 760px) {
			margin-top: 15px;
			padding: 50px 15px;
		}

		.grid {
			display: grid;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 5px;
			overflow: hidden;
			background: #5b5b5b30;

			div {
				grid-area: 1 / 1 / span 1 / span 1;
			}

			.bar {
				height: 100%;
				background: #e20613;
				transition: 0.5s ease-in-out;
			}

			.dashes {
				z-index: 1;
				height: 5px;
				display: flex;
				justify-content: space-evenly;

				.dash {
					width: 3px;
					height: 5px;
					background: #ffffff;
				}
			}
		}

		.steps {
			position: absolute;
			top: 20px;
			left: 50%;
			transform: translateX(-50%);
			font-weight: 300;
			color: #999999;
		}
	}
</style>
